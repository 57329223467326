import React from 'react';
import { Container, StyledLink, StyledStaticLink } from './nav.css';

const Nav = () => (
  <Container>
    <ul>
      <li>
        <StyledLink to="/">Home</StyledLink>
      </li>
      <li>
        <StyledLink to="/chi-sono">Chi sono</StyledLink>
      </li>
      <li>
        <StyledStaticLink href="https://dev.fisioterapistapistoia.it/blog">Blog</StyledStaticLink>
      </li>
    </ul>
  </Container>
);

export default Nav;
