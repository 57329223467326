import styled from 'styled-components';
import { Link } from 'gatsby';
import MEDIA from 'helpers/mediaTemplates';

export const StyledLink = styled(Link)`
  color: #1a5d8f;
  font-size: 3rem;
  ::hover {
    color: #1a5d8f;
  }
`;

export const StyledStaticLink = styled.a`
  color: #1a5d8f;
  font-size: 3rem;
  ::hover {
    color: #1a5d8f;
  }
`;

export const Container = styled.nav`
  ul {
    display: flex;
    list-style: none;
    padding: 0;

    ${MEDIA.TABLET`
      flex-direction: column;
    `};

    li {
      text-transform: uppercase;
      font-size: 1.3rem;

      & + li {
        margin-left: 2rem;
      }
    }
  }
`;
