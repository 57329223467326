import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 100vw;
  min-height: 25vh;

  /* background-color: #1a5d8f; */

  border-top: 1px solid #1a5d8f;
  box-shadow: 0 0 5px #1a5d8f;

  padding: 2rem;

  ${MEDIA.TABLET`
    flex-direction: column;
    height: 100vh;
  `};
`;

export const CopyrightRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  max-width: 100vw;
  min-height: 3rem;

  background-color: #1a5d8f;
  color: #fff;
  border-top: 1px solid #1a5d8f;
  box-shadow: 0 0 5px #1a5d8f;

  padding: 2rem;

  ${MEDIA.TABLET`
    flex-direction: column;
    height: 100vh;
  `};
`;

export const CopyrightParagraph = styled.p`
  margin-bottom: 0;
`;

export const Figure = styled.figure`
  width: 100vw;
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
  height: 100%;
  ${MEDIA.TABLET`
    width: 100vw;
  `};
`;

export const Title = styled.span`
  color: #1a5d8f;
  font-weight: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '400';
      default:
        return '500';
    }
  }};
  font-size: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '3.2rem';
      default:
        return '2rem';
    }
  }};
  line-height: 1.2;

  ${MEDIA.TABLET`
    font-size: ${({ size }) => () => {
      switch (size) {
        case 'large':
          return '2.6rem';
        default:
          return '2rem';
      }
    }};
  `};
`;

export const StyledImg = styled(Img)`
  min-width: 10vw;
  display: block;
  max-width: 20vw;
  width: 100%;
  background-color: #fff;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5rem;
  ${MEDIA.TABLET`
    margin-left: 0;
    width: 100%;
  `};
`;

export const IconContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
  /* justify-content: center; */
  align-items: center;
`;

export const StyledIconImg = styled(Img)`
  width: 1.5rem;
  display: inline-block;
  /* background-color: #fff; */
  padding: 1rem;
`;

export const StyledIconLink = styled.a`
  color: #1a5d8f;
  margin-left: 2rem;
`;
export const BrandContainer = styled.div`
  display: flex;
`;
